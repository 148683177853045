package screen.home.section

import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import util.toClassname

val HeaderSection = FC<Props> {
    section {
        className = "flex flex-col h-screen w-full justify-center max-w-2xl mx-auto".toClassname()

        h1 {
            className = "title-medium pb-4 md:pb-6".toClassname()
            + "\uD83D\uDC4B\uD83D\uDC4B HI THERE, I'M"
        }
        h2 {
            className = "title-big text-slate-800 dark:text-lime-300".toClassname()
            + "RUDIYANTO."
        }
        h3 {
            className = "title-big text-slate-400".toClassname()
            + "A mobile apps tinkerer"
        }
        p {
            className = "text-l md:text-xl pt-4 md:pt-6 text-justify dark:text-slate-300".toClassname()
            + "I'm a software engineer specialized in building mobile apps using various tech stack. Currently building features @ "
            a {
                className = "link".toClassname()
                href = "https://agoda.com"
                target = AnchorTarget._blank
                + "Agoda"
            }
        }
    }
}