package screen.home.section

import data.Company
import data.WorkExperience
import modules.ReactImageLightbox
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.section
import react.dom.html.ReactHTML.ul
import react.useState
import util.toClassname

val experiences = listOf(
    WorkExperience(
        title = "Software Engineer - Android Apps",
        company = Company(name = "Agoda", url = "https://agoda.com"),
        period = "Oktober 2022 - Present"
    ),
    WorkExperience(
        title = "Software Engineer - Android Apps",
        company = Company(name = "Bukalapak", url = "https://bukalapak.com"),
        period = "December 2018 - Oktober 2022",
        keyPoints = listOf(
            "Worked closely with multi-disciplinary teams of engineers, designers and product to build easy and secure login process on Bukalapak's mobile app.",
            "Built and enhanced user security features on Bukalapak's android app, such as PIN, OTP, Device History Log, and many more.",
            "Contributed on Bukalapak's new vertical android app development until its release. Built using Flutter."
        ),
        imgList = listOf(
            "bl_1.jpg",
            "bl_2.jpg",
            "bl_3.jpg",
            "bl_4.jpg",
        )
    ),
    WorkExperience(
        title = "Front-end Developer",
        company = Company(name = "PT. Inovasi Dunia Kreasi (skydreem)"),
        period = "September 2017 - July 2018",
        keyPoints = listOf(
            "Contributed in company's ongoing SaaS projects using Angular 2 (web) and Nativescript (mobile apps)."
        )
    ),
    WorkExperience(
        title = "Software Engineer Intern",
        company = Company(name = "Traveloka", url = "https://traveloka.com"),
        period = "June 2017 - September 2017",
        keyPoints = listOf(
            "Assigned to do a POC on third party's referral coupon system for marketing team."
        )
    )
)

data class ImagePopupState(val show: Boolean, val imgGroup: List<String>, val idx: Int)

private fun getImgPath(img: String) = "img/workexp/$img"

val WorkExperienceSection = FC<Props> {
    var popupState by useState(ImagePopupState(false, emptyList(), 0))

    section {
        className = "py-8 md:pt-36 section-narrow".toClassname()

        h2 {
            className = "section-title".toClassname()
            + "Work Experiences"
        }

        div {
            className = "relative m-auto".toClassname()

            div {
                className = "border-r-2 border-rose-900 dark:border-rose-400 absolute h-full top-0 left-[15px]".toClassname()
            }
            ul {
                className = "work-exp".toClassname()

                experiences.forEach {
                    li {
                        className = "work-exp-item".toClassname()

                        div {
                            className = "flex mb-2".toClassname()

                            div {
                                className = "timeline-dot".toClassname()
                            }
                            JobTitleSection {
                                title = it.title
                                company = it.company
                                period = it.period
                            }
                        }
                        KeyPointSection {
                            keyPoints = it.keyPoints
                        }
                        WorkImgSection {
                            imgList = it.imgList
                            onClick = { i ->
                                popupState = popupState.copy(show = true, idx = i, imgGroup = it.imgList)
                            }
                        }
                    }
                }
            }
        }

        if (popupState.show) {
            val idx = popupState.idx
            val imgLength = popupState.imgGroup.size
            val nextIdx = (idx + 1) % imgLength
            val prevIdx = (idx + imgLength - 1) % imgLength

            ReactImageLightbox {
                mainSrc = getImgPath(popupState.imgGroup[idx])
                nextSrc = getImgPath(popupState.imgGroup[nextIdx])
                prevSrc = getImgPath(popupState.imgGroup[prevIdx])
                onCloseRequest = { popupState = popupState.copy(show = false) }
                onMoveNextRequest = { popupState = popupState.copy(idx = nextIdx) }
                onMovePrevRequest = { popupState = popupState.copy(idx = prevIdx) }
            }
        }
    }
}

external interface KeyPointProps : Props {
    var keyPoints: List<String>
}
private val KeyPointSection = FC<KeyPointProps> {
    if (it.keyPoints.isEmpty()) return@FC

    ul {
        className = "work-exp-points".toClassname()

        it.keyPoints.forEach { p ->
            li {
                + p
            }
        }
    }
}

external interface WorkImgProps : Props {
    var imgList: List<String>
    var onClick: (Int) -> Unit
}
private val WorkImgSection = FC<WorkImgProps> {
    if (it.imgList.isEmpty()) return@FC

    div {
        className = "work-exp-img-container".toClassname()

        it.imgList.forEachIndexed { i, img ->
            ReactHTML.img {
                src = getImgPath(img)
                onClick = { _ ->
                    it.onClick(i)
                }
            }
        }
    }
}

external interface JobTitleProps: Props {
    var title: String
    var period: String
    var company: Company
}
private val JobTitleSection = FC<JobTitleProps> {
    div {
        className = "flex-1 ml-4".toClassname()

        div {
            className = "work-exp-title".toClassname()
            + "${it.title} @ "
            if (it.company.url == null) {
                + it.company.name
            } else {
                a {
                    href = it.company.url
                    target = AnchorTarget._blank
                    className = "link".toClassname()
                    + it.company.name
                }
            }
        }
        div {
            className = "work-exp-period".toClassname()
            + it.period
        }
    }
}