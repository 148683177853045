package screen.home.section

import react.FC
import react.Props
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.section
import util.toClassname

val ProjectsSection = FC<Props> {
    section {
        className = "py-8 md:pt-36 section-wide".toClassname()

        h2 {
            className = "section-title".toClassname()
            + "Things I've Built"
        }
        h2 {
            className = "text-[26px] dark:text-slate-300".toClassname()
            + "This section is under construction. Will be updated soon \uD83D\uDE09\uD83D\uDE09"
        }
    }
}