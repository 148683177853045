package util

import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass

fun updateTheme() {
    val localTheme = localStorage.getItem("theme")
    val isDark = localTheme == "dark" || (localTheme == null && window.matchMedia("(prefers-color-scheme: dark)").matches).let {
        localStorage.setItem("theme", if (it) "dark" else "light")
        it
    }
    document.documentElement?.let {
        if (isDark) it.addClass("dark") else it.removeClass("dark")
    }
}

fun toggleTheme() {
    val isDark = localStorage.getItem("theme") == "dark"
    localStorage.setItem("theme", if (!isDark) "dark" else "light")
    updateTheme()
}
