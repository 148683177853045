import react.Props
import react.create
import react.fc
import react.router.Route
import react.router.Routes
import react.router.dom.BrowserRouter
import react.router.dom.HashRouter
import screen.home.HomeScreen
import screen.portfolio.PortfolioScreen

val AppRoutes = fc<Props> {
    BrowserRouter {
        Routes {
            Route {
                attrs.path = "/portfolio"
                attrs.element = PortfolioScreen.create()
            }

            Route {
                attrs.path = "/"
                attrs.index = true
                attrs.element = HomeScreen.create()
            }
        }
    }
}