package screen.home.section

import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import screen.home.components.ImageProfile
import util.toClassname

private val abouts = listOf(
    "I'm a software engineer specialized in mobile app development. Throughout my career, I've worked for a variety of companies ranging from small software houses to large startups. " +
        "I'm constantly learning and staying up to date with the latest technologies and best practices. I'm dedicated to my work and take pride in the high-quality products that I create.",
    "I am skilled in various mobile application development technology such as: Android development (Java & Kotlin), React Native and Nativescript, " +
        "and other tools that support me on software development including: Code versioning with git and Scrum",
    "In my spare time, I usually spend my time playing games, playing badminton, read engineering or tech blog and do some personal project. " +
        "Lately i also start to practice my writing skill so that i can write my article too someday in the future."
)

val AboutSection = FC<Props> {
    section {
        className = "py-8 section-wide".toClassname()

        h2 {
            className = "section-title".toClassname()
            + "About Me"
        }

        div {
            className = "flex flex-col-reverse md:flex-row".toClassname()

            div {
                className = "about".toClassname()

                abouts.forEach {
                    p {
                        + it
                    }
                }
//                p {
//                    + "Currently working as a Software Engineer for Android apps @ "
//
//                    a {
//                        className = "link".toClassname()
//                        href = "https://agoda.com"
//                        + "Agoda"
//                    }
//                }
            }
            ImageProfile()
        }
    }
}