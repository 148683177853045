import kotlinext.js.require
import kotlinx.browser.document
import react.create
import react.dom.client.createRoot
import util.updateTheme

fun main() {
    require("./app.css")
    require("react-image-lightbox/style.css")

    updateTheme()

    document.getElementById("app")?.let {
        createRoot(it).render(AppRoutes.create())
    }
}