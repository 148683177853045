package screen.home.section

import data.SocialMediaIcon
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.section
import util.toClassname

val socialMedia = listOf(
    SocialMediaIcon(
        url = "https://www.linkedin.com/in/rudiyanto1410",
        target = AnchorTarget._blank,
        icon = "fab fa-linkedin-in"
    ),
    SocialMediaIcon(
        url = "https://github.com/rudii1410",
        target = AnchorTarget._blank,
        icon = "fab fa-github"
    ),
    SocialMediaIcon(
        url = "mailto:hello@rudiyanto.dev",
        icon = "fa fa-envelope")
)

val ContactSection = FC<Props> {
    section {
        className = "py-8 md:pt-36 section-narrow".toClassname()

        h2 {
            className = "section-title".toClassname()
            + "Get In Touch"
        }

        p {
            className = "text-justify md:text-center text-base dark:text-slate-300".toClassname()
            + "I'm not currently looking for new opportunities, but I'm open to connecting with new people. "
            + "Feel free to reach out to me with any questions or just to say hi. "
            + "I'll do my best to get back to you as soon as I can!"
        }

        div {
            className = "text-[42px] mt-4 text-center text-gray-700 dark:text-slate-300".toClassname()

            socialMedia.forEach {
                a {
                    className = "mx-6 hover:text-blue-800 dark:hover:text-cyan-400".toClassname()
                    href = it.url
                    target = it.target

                    i {
                        className = it.icon.toClassname()
                    }
                }
            }
        }
    }
}