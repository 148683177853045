package screen.home

import kotlinx.browser.document
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.footer
import screen.home.section.*
import util.toClassname
import util.toggleTheme

val HomeScreen = FC<Props> {
    document.title = "Rudiyanto.dev - Welcome to my site"

    HeaderSection()
    AboutSection()
    WorkExperienceSection()
    ProjectsSection()
    ContactSection()

    footer {
        className = "text-center py-12 text-sm text-gray-500".toClassname()
        + "Designed and Built by Rudiyanto."
        br()
        + "Copyright © 2023 Rudiyanto. All Rights Reserved.  "
        a {
            className = "link".toClassname()
            onClick = { toggleTheme() }
            + "Toggle Dark"
        }
    }
}
