package screen.portfolio

import react.FC
import react.Props
import react.dom.html.ReactHTML.div

val PortfolioScreen = FC<Props> {
    div {
        + "asd"
    }
}