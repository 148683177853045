package screen.home.components

import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import util.toClassname

private const val BLOB_SHAPE = "M56.3,-37.7C71.3,-26,80.8,-2.7,77.1,19.3C73.4,41.2,56.6,61.8,36,69.9C15.4,77.9,-8.8,73.3,-26.9,62.2C-44.9,51,-56.8,33.3,-59.9,15C-62.9,-3.3,-57.3,-22.2,-45.8,-33C-34.4,-43.8,-17.2,-46.6,1.7,-47.9C20.6,-49.3,41.3,-49.3,56.3,-37.7Z"

internal val ImageProfile = FC<Props> {
    img {
        className = "h-48 w-48 md:h-56 md:w-56 rounded-md md:rounded-full self-center mx-auto".toClassname()
        src = "img/self-no-bg.png"
    }
//    div {
//        className = "relative mb-6 md:mr-6".toClassname()
//
//        img {
//            className = "h-48 w-48 md:h-56 md:w-56 rounded-md md:rounded-full self-center mx-auto".toClassname()
//            src = "img/self-no-bg.png"
//        }
//        span {
//            className = "absolute -bottom-2 md:bottom-0 -z-10 left-1/2 -translate-x-1/2 text-blue-600".toClassname()
//
//            svg {
//                className = "h-52 w-52 md:h-72 md:w-72 fill-current".toClassname()
//                viewBox = "0 0 200 200"
//                xmlns = "http://www.w3.org/2000/svg"
//                ReactSVG.path {
//                    d = BLOB_SHAPE
//                    transform = "translate(100 100)"
//                }
//            }
//        }
//    }
}